import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';

import { useRef, useState } from 'react';
import { Autoplay, EffectCards, Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide, useSwiper } from 'swiper/react';
import styles from './BannerSlider.module.scss';
import { ArrowButton } from '../ArrowButton';
import cn from 'classnames';
import { BannerType, Variants } from '@/types';
import { Banner } from '../Banner';

import { Swiper as SwiperInstance } from 'swiper';
SwiperInstance.use([Autoplay, EffectCards]);

function BannerButton({ isNextButton }: { isNextButton?: boolean }) {
  const swiper = useSwiper();

  const handleClick = () => {
    if (isNextButton) {
      swiper.slideNext();
    } else {
      swiper.slidePrev();
    }
  };

  return isNextButton ? (
    <ArrowButton
      variant={Variants.Filled}
      onClick={handleClick}
      className={styles.next}
    />
  ) : (
    <ArrowButton
      variant={Variants.Filled}
      className={styles.prev}
      onClick={handleClick}
    />
  );
}

export function BannerSlider({
  className,
  banners,
  bannersMobile,
}: {
  className?: string;
  banners: BannerType[];
  bannersMobile: BannerType[];
  title?: string;
}) {
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef<SwiperClass | null>(null);

  const sliderClassName = cn(styles.slider, className);

  const sliderClassNameMobile = cn(styles.sliderMobile, className);

  const handleSlideChange = (swiper: SwiperClass) => {
    setIsStart(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
    swiper.autoplay.stop();
  };

  if (!banners) return null;

  return (
    <>
      <Swiper
        className={sliderClassNameMobile}
        effect="cards"
        modules={[Autoplay, EffectCards]}
        cardsEffect={{
          rotate: false,
          slideShadows: false,
          perSlideOffset: 9,
        }}
        loop
        centeredSlides
        loopAdditionalSlides={1}
        autoplay={{
          delay: 5000,
        }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {bannersMobile &&
          bannersMobile.map(
            (
              {
                id,
                name,
                tag,
                button,
                link,
                info,
                picture,
                pictureMob,
                video,
                videoMob,
                buttonLink,
              },
              index,
            ) => (
              <SwiperSlide key={`${id}-${index * 123}`}>
                <Banner
                  pictureMob={pictureMob}
                  name={name}
                  tag={tag}
                  button={button}
                  link={link}
                  info={info}
                  picture={picture}
                  buttonLink={buttonLink}
                  video={video}
                  videoMob={videoMob}
                />
              </SwiperSlide>
            ),
          )}
      </Swiper>
      <Swiper
        className={sliderClassName}
        pagination={{
          clickable: true,
          bulletClass: styles.bullet,
          bulletActiveClass: styles.bulletActive,
        }}
        modules={[Pagination, Autoplay]}
        spaceBetween={16}
        onSlideChange={handleSlideChange}
        speed={1000}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
      >
        {banners.length > 1 && (
          <>
            {isStart ? <div /> : <BannerButton />}
            {isEnd ? <div /> : <BannerButton isNextButton />}
          </>
        )}
        {banners &&
          banners.map(
            ({
              id,
              name,
              tag,
              button,
              link,
              info,
              picture,
              pictureMob,
              video,
              videoMob,
              buttonLink,
            }) => (
              <SwiperSlide key={id}>
                <Banner
                  pictureMob={pictureMob}
                  name={name}
                  tag={tag}
                  button={button}
                  link={link}
                  info={info}
                  picture={picture}
                  video={video}
                  videoMob={videoMob}
                  buttonLink={buttonLink}
                />
              </SwiperSlide>
            ),
          )}
      </Swiper>
    </>
  );
}
